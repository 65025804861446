<script setup lang="ts">
useHeadCommon()
</script>
<template>
  <div class="layout layout-protected">
    <AccessibilityQuickLinks />
    <main id="main-content" class="main">
      <slot />
    </main>
    <slot name="footer"> <Footer /> </slot>
  </div>
</template>
<style scoped lang="scss">
.main {
  padding-top: 0;
}
</style>
